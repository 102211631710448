<template>
    <div>
        <Loading  v-if="!requested" />
        <div v-else>
            <div class="cuisinItems">
                <div class="cuisine-items-wrapper">
                    <div class="cuisine-items-main">

                        <!-- webview -->
                        <div class="cuisine-item-logo">
                            <div class="cuisine-item-logo-wrapper">
                                <div class="cuisine-item-logo-main">
                                    <div class="left-logo">
                                        <a href="/" :class="{ 'logo-link': overlay}"><img :src="HomeLogo" alt="fastorder_logo" /></a>

                                        <van-overlay z-index="0" :show="overlay" @click="overlay = false" />
                                        <SearchBar :hideLogo="hideLogo" @activateOverlay="setOverlay" :overlay="overlay" />
                                    </div>
                                    
                                    <div class="right-logo_login">
                                        <div><a href="/account">Join Us</a></div>
                                        <span></span>
                                        <div><a href="/account">Sign In</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="main-cuisine-feature-info">
                            <div class="main-cuisine-feature-info-wrapper">
                                <h1>"{{features.name}}"</h1>
                                <h5 v-if="features.stores && features.stores.length>0">{{features.stores.length}} Restaurants</h5>
                                <h5 v-else>0 Restaurants</h5>
                            </div>
                        </div>

                        <div class="main-cuisine-feature">
                            <div class="main-cuisine-feature-wrapper">
                            <!-- <h1>"{{features.name}}"</h1>
                            <h5>{{features.stores.length}} Restaurants</h5> -->
                            <h2 v-if="features.stores">Featured</h2>
                            <div v-if="features.stores">
                            <swiper class="swiper main-cuisine-feature-cards" ref="swiper1"  :options="swiperOption3">
                                <div id="button-prev1" class="swiper-button-prev" slot="button-prev"></div>
                                <div id="button-next1" class="swiper-button-next" slot="button-next"></div>

                                <swiper-slide v-for="feature in features.stores" :key="feature.id" :name="feature.name">
                                    <div class="main-cuisine-feature-card">
                                        <div class="main-cuisine-feature-restaurant-info">
                                            <div class="restaurant-name">{{feature.name}}</div>
                                            <!-- <div class="restaurant-service"> Preorder, Takeaway, Delivery</div>
                                            <div class="restaurant-capita">Capita Consumption</div> -->
                                        </div>

                                        <div class="main-cuisine-feature-restaurant">
                                            <v-card width="150" class="featured-restaurant-logo" rounded="lg" @click="clickStore(feature)">
                                                <v-img height="130" :src="feature.thumbnail">
                                                </v-img>
                                            </v-card>
                                            <div class="main-cuisine-featured-trending-item">
                                                <div v-for="product in feature.products &&feature.products.slice(0,4)" :key="product.id" @click="clickFood(product,feature)">
                                                    <v-card width="63" rounded="lg" >
                                                        <v-img height="61" src="">
                                                        </v-img>
                                                    </v-card>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                    

                                </swiper-slide>
                            </swiper>
                            </div>
                        </div>
                        </div>
                        

                        <div class="cuisine-tabs">
                            <div class="tabs-main">
                                <v-tabs color="#ee2d24" v-model="activeIndex1">
                                    <v-tab v-for="(cuisine,i) in cuisines" :key="cuisine.id" :id="i" @click="scrollCuisine" :ref="'cuisineRef'+i" >{{cuisine.name}}</v-tab>
                                </v-tabs>
                            </div>
                        </div>

                        <div class="cuisines-type-wrapper">
                            <div class="cuisines-type-main">
                                <div class="cuisine-section" v-for="(cuisine, i) in cuisines" :key="cuisine.id"  :id="'cuisineID'+i">
                                    <h1>{{cuisine.name}}</h1>

                                    <div class="cuisine-restaurants">
                                        <div v-for="(store, i) in cuisine.stores" :key="i" class="cuisine-restaurants-section" >
                                        
                                            <v-card width="110" @click="clickStore(store)">
                                                <v-img height="110" :src="store.thumbnail"></v-img>
                                            </v-card>

                                            <div class="cuisine-restaurant-info">
                                                <div class="cuisine-restaurant-name">{{store.name}} </div>
                                                <div class="cuisine-restaurant-slider" v-if="store.products.length>0">
                                                    <swiper class="swiper" :options="swiperOption2" ref="swiper2"> 
                                                        <swiper-slide  v-for="(product, n) in store.products" :key="n">

                                                            <v-card height="70" class="restaurant-food-image-wrap" max-width="250" @click="clickFood(product,store)">
                                                            <v-card width="70" >
                                                                <v-img :src="product.thumb" height="60"></v-img>
                                                            </v-card>
                                                            <div class="restaurant-food-detail">
                                                                <div class="restaurant-food-name">{{product.name}}</div>
                                                                <div class="restaurant-food-price">RM {{product.marketprice}}</div>
                                                            </div>
                                                            </v-card>

                                                        </swiper-slide>
                                                        <div class="swiper-pagination" slot="pagination"></div>
                                                        <div class="swiper-button-prev btn-arrow-slider" slot="button-prev"></div>
                                                        <div class="swiper-button-next btn-arrow-slider" slot="button-next"></div>
                                                    </swiper>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- mobile view -->

                        <div class="cuisine-slider" :class="{ 'cuisine-slider_active':isScrollTop }">
                            <swiper class="swiper cuisine-slider-main" :options="swiperOption4"  ref="swiper4">
                                <div id="button-prev2" class="swiper-button-prev" slot="button-prev"></div>
                                <div id="button-next2" class="swiper-button-next" slot="button-next"></div>

                                <swiper-slide v-for="(cuisine,n) in cuisines" :key="cuisine.id">
                                    <div class="cuisine-item-image-wrap" @click="clickCuisine(n)">
                                        <v-card width="65">
                                            <v-img :src="cuisine.thumbnail" height="65"></v-img>
                                        </v-card>
                                        <div class="cuisine-item-image-text">
                                            {{cuisine.name}}
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>


                        <div class="cuisine-tabs-slide" :class="{ 'cuisine-tabs-slide_active':isScrollTop }">
                            <div class="cuisine-tabs-slide-main">
                            <div style="left:2px;" @click="swipeLeft" class="button-arrow" >
                                <v-btn max-height="30px" max-width="25px" icon color="#ee2d24">
                                <v-icon>{{ icons.mdiChevronLeft}}</v-icon>
                                </v-btn>      
                            </div>

                            <div style="right:4px;" @click="swipeRight" class="button-arrow">
                            <v-btn max-height="30px" max-width="25px" icon color="#ee2d24">
                                <v-icon>{{ icons.mdiChevronRight}}</v-icon>
                            </v-btn>      
                            </div>

                            <van-tabs  ref="scrollTab"  v-model="activeTab" color="#ee2d24">
                                <van-tab v-for="cuisine in cuisines" :key="cuisine.id" :title="cuisine.name">
                                </van-tab>
                            </van-tabs>
                        </div>
                        </div>

                        <div class="cuisine-item">
                            <div class="cuisine-item-header">
                                <h2>"{{features.name}}"</h2>
                                <h5 v-if="features.stores && features.stores.length>0">{{features.stores && features.stores.length}} Restaurants</h5>
                                <h5 v-else>0 Restaurants</h5>
                            </div>
                            <div class="cuisine-item-featured">
                                <h2 v-if="features.stores">Featured</h2>
                                <div v-if="features.stores">
                                    <swiper class="swiper" :options="swiperOption" ref="swiper3">
                                        <swiper-slide v-for="feature in features.stores" :key="feature.id" :name="feature.name" class="swiper-slider-wrap" >
                                            <div class="cuisine-item-featured-card">
                                                <div class="featured-store-name">{{feature.name}}</div>
                                                <!-- <div class="featured-store-service"> Preorder, Takeaway, Delivery</div>
                                                <div class="featured-store-capita">Capita Consumption</div> -->

                                                <div class="item-featured-image-wrap">
                                                    <v-card width="150" class="featured-restaurant-logo" rounded="lg" @click="clickStore(feature)">
                                                        <v-img height="130" :src="feature.thumbnail">
                                                        </v-img>
                                                    </v-card>
                                                    <div class="featured-trending-item">
                                                        <v-card v-for="product in feature.products &&feature.products.slice(0,4)" :key="product.id" width="63" rounded="lg" @click="clickFood(product,feature)">
                                                            <v-img :src="product.thumb" height="61">
                                                            </v-img>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                        <div class="swiper-pagination" slot="pagination"></div>
                                    </swiper>
                                </div>
                            </div>

                            <div class="cuisine-item-restaurants">
                                <h1>All Restaurants</h1>
                                <div class="restaurant-card" v-for="store in cuisines[activeTab].stores" :key="store.id">
                                    <div class="restaurant-info">
                                        <v-card class="restaurant-info-img-wrap" width="80" @click="clickStore(store)">
                                            <v-img :src="store.thumbnail" height="80"></v-img>
                                        </v-card>
                                        <div class="restaurant-info-text">
                                            <div class="restaurant-name">{{store.name}}</div>
                                            <!-- <div class="restaurant-service"> Preorder, Takeaway, Delivery</div>
                                            <div class="restaurant-capita">Capita Consumption</div> -->
                                        </div>
                                    </div>

                                    <div style="display:none"> 
                                        <span style="background:#6c757d;color:white;font-size:12px;font-weight:600;padding:0 3px;margin-right:3px">Closed</span>
                                        <span style="font-size:13px;font-weight:600;color:#ee2d24;background-color:rgb(255 225 223);padding:0 5px;margin-right:3px">Spend RM50,Save RM3</span>
                                        <span style="font-size:13px;font-weight:600;color:#ee2d24;background-color:rgb(255 225 223);padding:0 5px">Spend RM100,Save RM10</span>
                                    </div>
                                    <div class="restaurant-food-slider">
                                        <div v-for="product in store.products" :key="product.id">
                                            <v-card height="70" class="restaurant-food-image-wrap" width="260" @click="clickFood(product,store)">
                                                <v-card width="70" height="60">
                                                    <v-img :src="product.thumb" height="60"></v-img>
                                                </v-card>
                                                <div class="restaurant-food-detail">
                                                    <div class="restaurant-food-name">{{product.name}}</div>
                                                    <div class="restaurant-food-price">RM {{product.marketprice}}</div>
                                                </div>
                                        </v-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NavFooter />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from "../Loading.vue"
import { mdiChevronRight,mdiChevronLeft,mdiMagnify } from '@mdi/js';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import HomeLogo from '../../assets/logo2.png'
import NavFooter from "../../components/common/NavFooter.vue"
import { getStoreTag, getStoreCategory} from '@/components/api/api'
import SearchBar from '../../components/common/SearchBar.vue'
import { mainMixin } from '@/mixins'

export default {
name: "Explore",
components: {
    Loading,
    Swiper,
    SwiperSlide,
    NavFooter,
    SearchBar
},
data() {
    return {
        overlay:false,
        hideLogo:true,
        features : null,
        isScrollTop:false,
        mobile:false,
        results: [],
        searchQuery: "",
        icons: {
            mdiChevronRight,
            mdiChevronLeft,
            mdiMagnify
        },
        index : null,
        HomeLogo,
        swiperOption: {
            slidesPerView:1.1,
            spaceBetween: 10,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints:{
                350: {
                slidesPerView: 1.1,
                spaceBetween: 10
                },
                320: {
                slidesPerView: 1,
                spaceBetween: 20
                },
            }
        },
        swiperOption2: {
            slidesPerView: 1,
            spaceBetween: 20,
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },  
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        },
        swiperOption3: {
            slidesPerView: 4,
            spaceBetween: 40,
            navigation: {
                nextEl: '#button-next1',
                prevEl: '#button-prev1'
            },
            breakpoints: {
                1520: {
                slidesPerView: 4,
                spaceBetween: 40
                },
                1350: {
                slidesPerView: 3.5,
                spaceBetween: 10
                },
                1200: {
                slidesPerView: 3,
                spaceBetween: 40
                },
                1040: {
                slidesPerView: 2.5,
                spaceBetween: 10
                },
                840: {
                slidesPerView: 2,
                spaceBetween: 40
                },
                640: {
                slidesPerView: 1.5,
                spaceBetween: 40
                },
            }
        },
        swiperOption4: {
            slidesPerView: 6,
            spaceBetween: 5,
            navigation: {
                nextEl: '#button-next2',
                prevEl: '#button-prev2'
            },
            breakpoints: {
                460: {
                slidesPerView: 5,
                spaceBetween: 10
                },
                360: {
                slidesPerView: 4,
                spaceBetween: 10
                },
                270: {
                slidesPerView: 3,
                spaceBetween: 10
                },
            }
        },
        activeIndex1: 0,
        activeTab: 0,
        requested: false,
        cuisines: [],
        restaurants: [
            {
                name : 'Chicken Rice Shop',
                service : 'Preorder, Takeaway, Delivery',
                capita : 'Capita consumption 1'
            },
            {
                name : 'Char Kuew Tiaw Shop',
                service : 'Preorder , Takeaway',
                capita : 'Capita consumption 2'
                
            },
            {
                name : 'Restaurant Seafood',
                service : 'Pork free, Takeaway, Delivery',
                capita : 'Capita consumption 3'
            },
            {
                name : 'Seafood Noodle Cafe',
                service : 'Preorder, Takeaway, Delivery',
                capita : 'Capita consumption 4'
            },
            {
                name : 'Huen Kee',
                service : 'Delivery',
                capita : 'Capita consumption 5'
            },
            {
                name : 'Burger &Sample',
                service : 'Pork-free, Takeaway, Delivery',
                capita : 'Capita consumption 6'
            },
        ]
    }
},
mixins: [mainMixin],
created() {
    this.getData()
    this.handleResize()
    this.scrolledTop()

    if(window.innerWidth >640) {
        window.addEventListener("scroll", this.handleScroll);
    }
    window.addEventListener('resize', this.handleResize);

    window.addEventListener('scroll', this.scrolledTop);
    
},
beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);

    window.removeEventListener('resize', this.handleResize);

    window.removeEventListener('scroll', this.scrolledTop);
    
},
methods: {
    clickFood(food,store){
            const itemId =food.id
            const storeId = store.id
            const origin =  "https://merchant.fastorder.my"
            const pathname= "/v202105/"
            const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&itemid=${itemId}`
            const url = origin+pathname+hash
            window.location= url
        },
    clickStore(store){
        const storeId = store.id
        const origin =  "https://merchant.fastorder.my"
        const pathname= "/v202105/"
        const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&tabletype=delivery`
        const url = origin+pathname+hash
        window.location= url
    },
    clickCuisine(n){
        this.activeTab= n
    },
    setOverlay(){
        this.overlay= true;
    },
    getData(){
        this.getFeatureFood();
        this.getCuisineFood();
    },
    getCuisineFood(){
        getStoreCategory()
        .then(res =>{
            console.log('store list foodslide =>', res.data)
            this.cuisines = res.data
        }).catch(error => {
            console.log('Error! :',error)
        })
    },
    getFeatureFood(){
        getStoreTag()
            .then(res => {
                console.log('explore =>', res.data)
                const indexpage = res.data
                const id = this.$route.params.id
                const found = indexpage.find( el => el.name.toLowerCase() == id)
                this.features =  found
                console.log('feature',this.features)
                this.requested = true
            }).catch(error => {
                console.log('error :', error)
            })
    },

    swipeLeft() {
        const content = this.$refs.scrollTab.$refs.nav;
        this.scrollTo(content, -100, 400);
    },
    swipeRight() {
        
        const content = this.$refs.scrollTab.$refs.nav;
        this.scrollTo(content, 100, 400);
    },

    scrolledTop(){
        let scrollY = window.scrollY

        if(scrollY > 75){
            this.isScrollTop = true
        }else{
            this.isScrollTop= false
        }
    },
    handleResize() {
        this.mobile = window.innerWidth <= 640;

        if(!this.mobile){
            window.addEventListener("scroll", this.handleScroll);
        }else{
            window.removeEventListener("scroll", this.handleScroll);
        }
    },
    handleLocation(result){
        console.log(result)
        this.searchQuery = result.address +', '+ result.city +', '+ result.state
        document.getElementById('search-button').focus();
        },  
        async inputSearch(){
        if(this.searchQuery != ""){
            // console.log(this.searchQuery)
            const result = await this.index.search(this.searchQuery);
            let hits = result.hits;
            console.log('results',hits)
            this.results = hits

            // this.search()
        }else{
            // this.results = ""
        }
    },
    handleScroll(){
        let current = '';
        let element = document.querySelectorAll(".cuisine-section")
        let currScrollY = window.pageYOffset || document.documentElement.scrollTo 
        // console.log(element)

        element.forEach( el => {
            // initial value srolltop 152
            const elTop = el.offsetTop - 340;
            const elHeight = el.clientHeight;

            if(pageYOffset > elTop){
                current = el.getAttribute('id'); 
            } 
        })
        if(current){
            // separate number from word cuisineID
            current = current.slice(9)
            this.activeIndex1 = parseInt(current)
        }
    },
    test(){
        // console.log('refs',this.$refs["cuisineRef1"][0].$refs.link)
        // this.$refs["cuisineRef5"][0].$refs.link.click()
    },
    scrollCuisine(e){
        const id = e.target.id
        var element = document.getElementById(`cuisineID${id}`);
        var headerOffset = 152;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;
        document.documentElement.scrollTop = offsetPosition;
        document.body.scrollTop = offsetPosition; // For Safari

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    },
    goBack(){
        history.back()
    },
},

}
</script>

<style lang='scss'>
@import "../../components/common/css/global.scss";
@import "../../components/common/css/common.scss";

.border__divider{
    height: 8px;
    background: $white5;
    width: 100%;
    display: block;
}
.border__divider_yellow{
    height: 1px;
    background: $bgColor3;
    width: 100%;
    display: block;
}

.cuisine-items-main{
    padding: 0;
    @media screen and (max-width: $bp-mobile){
        position: relative;
    }
    
    & .button_back{
        display:none;
        align-items:center;
        cursor:pointer;
        width:fit-content;
        @media screen and (max-width: $bp-mobile){
        display: flex;
        }
    }
}

// webview section

.cuisine-item-logo{
    position: sticky;
    top: 0;
    background: $white;
    z-index: 4;
    display: block;

    & .cuisine-item-logo-wrapper{
        padding: 0 40px;

        @media screen and (max-width: $bp-mobile){
            padding: 0;
        }

        & .cuisine-item-logo-main{
            margin: 0 auto;
            max-width: 1600px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: $bp-mobile){
                height: 60px;
            }
        }

    }
}

.left-logo{
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $bp-mobile){
        padding: 0 10px;
    }

    & a {
        margin-right: 20px;

        @media screen and (max-width: $bp-mobile){
            margin-right: 5px;
        }

        & img{
            display: block;
            height: 40px;
            
            @media screen and (max-width: $bp-mobile){
                height:30px;
            }
        }
    }

    .logo-link{
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }
}
.right-logo_login{
    display: flex;
    align-items: center;
    list-style: none;
    white-space: nowrap;
    
    & div{
        padding: 10px;
        font-size: 20px;
        font-family: roboto;
        font-weight: 600;
        a{
            color: $red1;
        }
    }
    & span {
        width: 2px;
        height: 20px;
        background: $bgColor2;
    }

    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}

.main-cuisine-feature-info{
    padding: 20px 40px 0;
    margin-top:30px;
    background:$white;
    position:sticky;
    top:80px;
    z-index:2;
    height: 110px;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}
.main-cuisine-feature-info-wrapper{
    max-width: 1600px;
    margin: 0 auto;
    & h1{
        font-family: roboto;
        font-size: 40px;
    }
    & h5 {
        font-family: roboto;
        color: $gray4;
    }
}
.main-cuisine-feature{
    margin-top: 30px;
    padding: 0 40px;
    display: block;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
.main-cuisine-feature-wrapper{
    display: block;
    max-width: 1600px;
    margin: 0 auto;
    /* padding: 0 40px; */
    font-family: roboto;

    & h1{
        font-family: roboto;
        font-size: 40px;
    }
    & h5 {
        font-family: roboto;
        color: $gray4;
    }
    & h2{
        font-family: roboto;
        margin-top: 20px;
    }


    & .main-cuisine-feature-cards{
        padding: 10px 40px 20px 45px;
        margin-bottom: 20px;

        .main-cuisine-feature-card{
            display: block;
            box-shadow: 2px 2px 5px 2px $white3;
            border-radius: 5px;
            padding: 15px;
            width: 300px !important;
        }
        #button-prev1{
            background: $bgColor2;
            padding: 10px 3px !important;
            color:white;
            height: 60px;
            width: 40px;
            left: 0px;
            top: 50%;
            border-radius: 3px;
        }
        & .swiper-button-disabled {
            opacity: 0.2 ;
        }
        #button-next1{
            background: $bgColor2;
            padding: 10px 3px !important;
            color:white;
            height: 60px;
            width: 40px;
            top: 50%;
            border-radius: 3px;
        }

        & .main-cuisine-feature-restaurant-info{
            padding: 5px 3px;
            margin-bottom: 5px;
            & .restaurant-name{
                height: 24px;
                color: $gray2;
                overflow: hidden;
                font-family: roboto;
                font-weight: 600;
            }
            & .restaurant-service{
                height: 20px;
                font-family: roboto;
                font-weight: 400;
                font-size: 14px;
            }
            & .restaurant-capita{
                height: 20px;
                font-family: roboto;
                font-weight: 200;
                font-size: 13px;
            }
        }
        & .main-cuisine-feature-restaurant{
            display: flex;
            /* margin-right: 30px; */

            & .main-cuisine-featured-trending-item {
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr !important;
                grid-row-gap: 7px;
                grid-column-gap: 10px;
                justify-content: space-between;
                margin-left: 10px;
            }
        }
    }
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}

}


.cuisine-tabs{
    display: block;
    background: $white;
    position: sticky;
    position: -webkit-sticky;
    top: 190px; /* required */
    z-index: 3;
    box-shadow: 0 5px 5px 0 $white3;
    padding: 0 40px;
    & .tabs-main{
        margin: 0 auto;
        max-width: 1600px;

        & .v-slide-group__prev--disabled{
            display: none !important;
        }
    }

    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}


.cuisines-type-wrapper{
    display: block;
    padding: 72px 40px 200px;
    background: $white5;
    & .cuisines-type-main{
        width: 100%;
        margin: 0 auto;
        max-width: 1600px;
    }
    @media screen and (max-width: $bp-mobile){
        display:none;
    }
}

.cuisine-section:first-child{
    & h1 {
        margin: 0 0 24px;
    }
}
.cuisine-section{
    & h1{
        font-size: 33px;
        margin: 72px 0 24px;
    }
    & .cuisine-restaurants{
        display: grid;
        grid-gap: 5px;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(3,1fr) !important;
        @media screen and (max-width: 1280px){
            grid-template-columns: repeat(2,1fr) !important;
        }
        @media screen and (max-width: 930px){
            grid-template-columns: 1fr !important;
        }
        & .cuisine-restaurants-section{
            box-shadow: 2px 2px 8px 0 $white3;
            background: $white;
            border-radius: 5px;
            display: flex;
            padding: 16px 0 16px 16px;
            width: 510px;
            @media screen and (max-width: 1600px){
                padding:10px 10px 20px;
                max-width: 430px;
                width: 100%;
            }
            @media screen and (max-width: 1600px){
                padding:10px 0px 20px 10px;
            }
        }
    }

    & .cuisine-restaurant-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 16px;
        @media screen and (max-width: 1600px){
            margin-left: 10px;
        }

        & .cuisine-restaurant-name{
            height: 22px;
            overflow: hidden;
            padding-right: 16px;
            font-weight: 600;
            font-size: 15px;
            font-family: roboto;
        }

        & .cuisine-restaurant-slider::-webkit-scrollbar {
            display: none;
        }
        & .cuisine-restaurant-slider{
            width: 340px;
            @media screen and (max-width: 1600px){
                max-width: 290px;
                width: 100%;
            }

            & .swiper {
                padding: 5px 0;
                margin-left: -8px;

                @media screen and (max-width: 1340px){
                    max-width: 285px;
                }
                & .restaurant-food-image-wrap{
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    @media screen and (max-width: 1600px){
                        width: 220px;
                    }
                    @media screen and (max-width: 1400px){
                        width: 210px;
                    }
                }
                & .restaurant-food-detail{
                    padding: 0 5px 0 10px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                & .restaurant-food-name{
                    margin-top: 5px;
                    font-size: 11px;
                    line-height: 14px;
                    max-height: 28px;
                    overflow: hidden;

                }
                & .restaurant-food-price{
                    font-size: 12px;
                    color: $yellow1;
                    font-weight: 600;
                }

                & .swiper-pagination{
                    display: none;
                    position: absolute;
                    bottom: -5px;
                }
                .swiper-button-prev:after, .swiper-button-next:after {
                    color: $white;
                    font-size: 12px !important;
                    background: $bgColor2;
                    border-radius: 2px;
                    height: 30px;
                    width: 20px;
                    display: grid;
                    place-items: center;
                    @media screen and (max-width: 1600px){
                        width: 14px;
                    }
                }
                .swiper-button-disabled{
                    opacity: 0.2 !important;
                }
            }
    }
    }
}



//mobileview section

.cuisine-slider{
    display:none;
    border-top: 8px solid $white5;
    padding: 10px 14px;
    margin: 10px 0;
    overflow: hidden;

    & .cuisine-slider-main{
        padding: 0 0 0 20px;
        overflow: visible;

        & #button-prev2{
            background: $bgColor2;
            padding: 10px 0px !important;
            color:$white;
            font-size: 10px;
            left: -10px;
            top: 50%;
            border-radius: 3px;
            width: 20px;
            height: 40px;
        }
        & #button-next2{
            background: $bgColor2;
            padding: 10px 3px !important;
            color:$white;
            font-size: 10px;
            right: -10px;
            top: 50%;
            border-radius: 3px;
            width: 20px;
            height: 40px;
        }
        .swiper-button-prev:after{
            font-size: 1rem;
        }
        .swiper-button-next:after{
            font-size: 1rem;
        }
    }

    & .cuisine-item-image-wrap::after{
        position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: $rgb-bgColor1;
            z-index: 1;
            border-radius: 4px;
    }
    & .cuisine-item-image-wrap{
        position: relative;
        width: 65px;

        & .cuisine-item-image-text{
            z-index: 3;
            bottom: 3px;
            left: 2px;
            position: absolute;
            font-size: 11px;
            font-weight: 800;
            text-align: center;
            width: 65px;
            overflow: hidden;
            color: $white;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    @media screen and (max-width: $bp-mobile){
        display: block;
        transform: scaleX(1) translateY(0);
        transition: 0.2s ease-in;
    }
}

.cuisine-slider_active{
    transform: scale(0) translateY(-30%);
}

.cuisine-tabs-slide{
        display: none;
        & .cuisine-tabs-slide-main{
            & .van-tabs__nav{
                & .van-tabs__line{
                    display: none;
                }
                & .van-tab--active::after{
                    position: absolute;
                    content: "";
                    bottom: 0;
                    height: 3px;
                    background: $bgColor2;
                    left: 0;
                    right: 0;
                    margin: 0 12px;
                    transition: 0.3s ease-in;
                    border-radius: 1px;
                }
            }
        }
        @media screen and (max-width: $bp-mobile){
            transform:scaleX(0) translateY(-500%); 
            position: fixed;
            display: block;
            width: 100%;
            top:60px;
            z-index: 2;
            padding: 0 15px;
            background: $white;
            box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.1);
            transition: 0.3s ease-out;
        }
}
.cuisine-tabs-slide_active{
    @media screen and (max-width: $bp-mobile){
        transform:scaleX(1) translateY(0);
    }
}

.cuisine-tabs-slide-main{
    & .button-arrow{
        position: absolute;
        top: 8px;
        z-index: 2;
    }
}

.cuisine-item{
    background: $white5;
    display: none;
    @media screen and (max-width: $bp-mobile){
        display: block;
    }
    & .cuisine-item-header{
        padding: 10px 14px;
        margin-top: 30px;
        & .cuisine-item-header__link{
            margin: 10px 0;
            & a{
                text-decoration: none;
            }
            & a:last-child{
                color: $gray0;
            }
        }

        & h5{
            font-weight: 400;
            color: rgb(139, 139, 139);
        }
    }
    
}

.cuisine-item-featured{
    padding: 0 14px;
    & h2 {
        margin: 10px 0 0;
        color: $gray3;
        font-size: 18px;
    }
    .featured-store-name{
        font-size: 18px;
        color: $gray0;
        font-weight: 600;
        font-family: Roboto;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 5px;
    }
    .featured-store-service{
        font-size: 13px;
        color: $gray1;
        font-weight: 600;
        font-family: Roboto;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 5px;
    }
    .featured-store-capita{
        font-size: 12px;
        color: $gray3;
        font-weight: 400;
        font-family: Roboto;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 5px;
    }

        & .swiper{
            padding-bottom: 20px;
            & .swiper-slider-wrap{
                padding: 10px 0;
            }
            .swiper-container {
                overflow: visible;
 
            }
            .swiper-pagination {
                position: absolute;
                bottom: 0px; // should be fine as is, but adjust to your liking
            }

            & .swiper-slider-wrap:nth-child(1){
                /* margin-left: -20px ; */
                /* margin-right: 20px; */
            }
            & .item-featured-image-wrap{
                margin-top: 5px;
            display: flex;
            width: fit-content;

            & .featured-trending-item{
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr !important;
                grid-row-gap: 7px;
                grid-column-gap: 10px;
                justify-content: space-between;
                margin-left: 10px;
            }
        }
        }

}

.cuisine-item-restaurants{
    margin-top: 10px;
    padding: 10px 0 400px;
    & h1{
        padding: 0 14px;
        font-size: 20px;
        color: $gray3;
        font-weight: 600;
    }
}

.restaurant-card{
    padding: 20px 14px 10px;
    border-bottom: 1px solid $yellow0;
    
    & .restaurant-info{
        display: flex;
        margin-bottom: 5px;
        & .restaurant-info-img-wrap{
            margin-right: 16px;
        }
        & .restaurant-info-text{
            .restaurant-name{
                font-size: 15px;
                color: $gray0;
                font-weight: 600;
                margin-bottom: 3px;
            }
            .restaurant-service{
                font-size: 13px;
                color: black;
                font-weight: 400;
                margin-bottom: 3px;
            }
            .restaurant-capita{
                font-size: 12px;
                color: $gray4;
                font-weight: 300;
            }
        }
    }

    & .restaurant-food-slider::-webkit-scrollbar{
        display: none;
    }
    & .restaurant-food-slider{
        display: flex;
        overflow-x: scroll;
        padding:5px 0 10px;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        & .restaurant-food-image-wrap{
            margin-right: 10px;
            display: flex;
            align-items: center;
            padding-left: 5px;
        }

        & .restaurant-food-detail{
            padding: 10px 5px 10px 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        & .restaurant-food-name{
            font-size: 11px;
            line-height: 14px;

        }
        & .restaurant-food-price{
            font-size: 12px;
            color: $yellow1;
            font-weight: 600;
        }
    }
}

    
</style>    